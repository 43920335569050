import { formatNum } from '@/utils/index.js'

// 应交条形柱状图
export function bar(title, legendData, res2) {
  const otddNumber = []
  const otDelayNumber = []
  const preYearOtDelayNumber = []
  const preYearOtddNumber = []
  let maxNum = ''
  res2.map(item => {
    otddNumber.push(item.otddNumber)
    otDelayNumber.push(item.otDelayNumber)
    preYearOtDelayNumber.push(item.preYearOtDelayNumber)
    preYearOtddNumber.push(item.preYearOtddNumber)
  })
  const maxArr = [otddNumber, otDelayNumber, preYearOtDelayNumber, preYearOtddNumber]
  maxNum = maxArr[0][0]
  maxArr.map(item => {
    maxNum < Math.max(...item) ? maxNum = Math.max(...item) : ''
  })
  // console.log(maxNum)
  const series = [
    {
      name: '应交准交',
      type: 'bar',
      stack: 'due',
      label: {
        normal: {
          show: true,
          formatter: function(params) {
            if (params.value > 0 && Math.ceil(maxNum / 15)) {
              return formatNum(params.value)
            } else {
              return ''
            }
          }
        }
      },
      itemStyle: {
        color: '#5470C6'
      },
      emphasis: {
        focus: 'series'
      },
      data: otddNumber

    },
    {
      name: '应交延期',
      type: 'bar',
      stack: 'due',
      label: {
        normal: {
          show: true,
          formatter: function(params) {
            if (params.value > 0 && Math.ceil(maxNum / 15)) {
              return formatNum(params.value)
            } else {
              return ''
            }
          }
        }
      },
      itemStyle: {
        color: '#FAC858'
      },
      emphasis: {
        focus: 'series'
      },
      data: otDelayNumber
    },
    {
      name: '上一年应交准交',
      type: 'bar',
      stack: 'lastyear',
      label: {
        normal: {
          show: true,
          formatter: function(params) {
            if (params.value > 0 && Math.ceil(maxNum / 15)) {
              return formatNum(params.value)
            } else {
              return ''
            }
          }
        }
      },
      itemStyle: {
        color: '#91CC75'
      },
      emphasis: {
        focus: 'series'
      },
      data: preYearOtddNumber
    },
    {
      name: '上一年应交延期',
      type: 'bar',
      stack: 'lastyear',
      label: {
        normal: {
          show: true,
          formatter: function(params) {
            if (params.value > 0 && Math.ceil(maxNum / 15)) {
              return formatNum(params.value)
            } else {
              return ''
            }
          }
        }
      },
      itemStyle: {
        color: '#EE6666'
      },
      emphasis: {
        focus: 'series'
      },
      data: preYearOtDelayNumber
    }
  ]
  series.push({
    type: 'bar',
    stack: 'due',
    label: {
      normal: {
        show: true,
        position: 'right',
        formatter: (val) => {
          const sum = []
          for (var i = 0, l = series.length; i < l; i++) {
            sum.push(series[i].data[val.dataIndex])
          }

          const num = (sum[0] * 100 / (sum[0] + sum[1])).toFixed(2)
          if (num && !isNaN(num)) {
            return num + '%'
          } else return ''
        }
      }
    },
    itemStyle: {
      color: '#000'
    },
    emphasis: {
      focus: 'series'
    },
    data: [0, 0, 0, 0, 0, 0]

  },
  {
    type: 'bar',
    stack: 'lastyear',
    label: {
      normal: {
        show: true,
        position: 'right',
        formatter: (val) => {
          const sum = []
          for (var i = 0, l = series.length; i < l; i++) {
            sum.push(series[i].data[val.dataIndex])
          }
          const num = (sum[2] * 100 / (sum[2] + sum[3])).toFixed(2)
          if (num && !isNaN(num)) {
            return num + '%'
          } else return ''
        }
      }
    },
    itemStyle: {
      color: '#000'
    },
    emphasis: {
      focus: 'series'
    },
    data: [0, 0, 0, 0, 0, 0]

  })
  const y = []
  res2 && res2.map(item => y.push(item.title))
  return {
    title: {
      text: title,
      x: 'center',
      top: 0,
      // textVerticalAlign: 'auto',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: 20,
        height: 20,
        color: '#333'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'

      },
      formatter: function(params) {
        let str = params[0].name
        for (let i = 0; i < params.length - 1; i++) {
          params[i].seriesName.indexOf('series') === -1 ? str += '<br/>' + params[i].marker + params[i].seriesName + ' ' + formatNum(params[i].value) : ''
        }
        return str
      }
    },
    legend: {
      data: legendData,
      x: 'center',
      y: 'bottom'
    },
    grid: {
      left: '1%',
      right: '8%',
      bottom: '8%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      axisTick: {
        show: false
      }
    },
    yAxis: [{
      type: 'category',
      data: y,
      position: 'left'
    }],
    series: series
  }
}

// 延期柱状图
export function otdReviewbar(title, legendData, res2) {
  const reviewDdNumber = []
  const reviewDelayNumber = []
  const preYearReviewDdNumber = []
  const preYearReviewDelayNumber = []
  let maxNum = ''
  res2.map(item => {
    reviewDdNumber.push(item.reviewDdNumber)
    reviewDelayNumber.push(item.reviewDelayNumber)
    preYearReviewDdNumber.push(item.preYearReviewDdNumber)
    preYearReviewDelayNumber.push(item.preYearReviewDelayNumber)
  })
  const maxArr = [reviewDdNumber, reviewDelayNumber, preYearReviewDdNumber, preYearReviewDelayNumber]
  maxNum = maxArr[0][0]
  maxArr.map(item => {
    maxNum < Math.max(...item) ? maxNum = Math.max(...item) : ''
  })
  const series = [
    {
      name: '评审准交',
      type: 'bar',
      stack: 'due',
      label: {
        normal: {
          show: true,
          formatter: function(params) {
            if (params.value > 0 && Math.ceil(maxNum / 15)) {
              return formatNum(params.value)
            } else {
              return ''
            }
          }
        }
      },
      itemStyle: {
        color: '#5470C6'
      },
      emphasis: {
        focus: 'series'
      },
      data: reviewDdNumber

    },
    {
      name: '评审延期',
      type: 'bar',
      stack: 'due',
      label: {
        normal: {
          show: true,
          formatter: function(params) {
            if (params.value > 0 && Math.ceil(maxNum / 15)) {
              return formatNum(params.value)
            } else {
              return ''
            }
          }
        }
      },
      itemStyle: {
        color: '#FAC858'
      },
      emphasis: {
        focus: 'series'
      },
      data: reviewDelayNumber
    },
    {
      name: '上一年评审准交',
      type: 'bar',
      stack: 'lastyear',
      label: {
        normal: {
          show: true,
          formatter: function(params) {
            if (params.value > 0 && Math.ceil(maxNum / 15)) {
              return formatNum(params.value)
            } else {
              return ''
            }
          }
        }
      },
      itemStyle: {
        color: '#91CC75'
      },
      emphasis: {
        focus: 'series'
      },
      data: preYearReviewDdNumber
    },

    {
      name: '上一年评审延期',
      type: 'bar',
      stack: 'lastyear',
      label: {
        normal: {
          show: true,
          formatter: function(params) {
            if (params.value > 0 && Math.ceil(maxNum / 15)) {
              return formatNum(params.value)
            } else {
              return ''
            }
          }
        }
      },
      itemStyle: {
        color: '#EE6666'
      },
      emphasis: {
        focus: 'series'
      },
      data: preYearReviewDelayNumber
    }
  ]
  series.push({
    type: 'bar',
    stack: 'due',
    label: {
      normal: {
        show: true,
        position: 'right',
        formatter: (val) => {
          const sum = []
          for (var i = 0, l = series.length; i < l; i++) {
            sum.push(series[i].data[val.dataIndex])
          }
          const num = (sum[0] * 100 / (sum[0] + sum[1])).toFixed(2)
          if (num && !isNaN(num)) {
            return num + '%'
          } else return ''
        }
      }
    },
    itemStyle: {
      color: '#000'
    },
    emphasis: {
      focus: 'series'
    },
    data: [0, 0, 0, 0, 0, 0]

  },
  {
    type: 'bar',
    stack: 'lastyear',
    label: {
      normal: {
        show: true,
        position: 'right',
        formatter: (val) => {
          const sum = []
          for (var i = 0, l = series.length; i < l; i++) {
            sum.push(series[i].data[val.dataIndex])
          }
          const num = (sum[2] * 100 / (sum[2] + sum[3])).toFixed(2)
          if (num && !isNaN(num)) {
            return num + '%'
          } else return ''
        }
      }
    },
    itemStyle: {
      color: '#000'
    },
    emphasis: {
      focus: 'series'
    },
    data: [0, 0, 0, 0, 0, 0]

  })
  const y = []
  res2 && res2.map(item => y.push(item.title))
  return {
    title: {
      text: title,
      x: 'center',
      top: 0,
      // textVerticalAlign: 'auto',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: 20,
        height: 20,
        color: '#333'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'

      },
      formatter: function(params) {
        let str = params[0].name
        for (let i = 0; i < params.length - 1; i++) {
          params[i].seriesName.indexOf('series') === -1 ? str += '<br/>' + params[i].marker + params[i].seriesName + ' ' + formatNum(params[i].value) : ''
        }
        return str
      }
    },
    legend: {
      data: legendData,
      x: 'center',
      y: 'bottom'
    },
    grid: {
      left: '1%',
      right: '8%',
      bottom: '8%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      axisTick: {
        show: false
      }

    },
    yAxis: [{
      type: 'category',
      data: y,
      position: 'left'

    }],
    series: series
  }
}

// 折线图
export function line(title, series, xDataKey, legendData) {
  // console.log(series)
  return {
    dataZoom: [{
      type: 'inside',
      start: 50,
      end: 100

    }, {}],
    title: {
      text: title,
      x: 'center',
      top: 0,
      // bottom: '3%',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: 20,
        height: 20,
        color: '#333'
      }
    },
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        let str = params[0].name
        for (let i = 0; i < params.length; i++) {
          if (params[i].seriesName) {
            params[i].value === 0 ? str += '<br/>' + params[i].marker + params[i].seriesName + '： ' + params[i].value : params[i].value === 1 ? str += '<br/>' + params[i].marker + params[i].seriesName + '： ' +
           ((params[i].value * 100)) + '%' : str += '<br/>' + params[i].marker + params[i].seriesName + '： ' +
           ((params[i].value * 100).toFixed(2)) + '%'
          }
        }
        return str
      }

    },
    legend: {
      data: legendData,
      type: 'scroll',
      x: 'center',
      y: 'top',
      top: '8%',
      bottom: '4%'
    },
    grid: {
      top: '18%',
      left: '8%',
      right: '8%',
      bottom: '8%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xDataKey
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function(value) {
          return (value * 100).toFixed(2) + '%'
        }
      },
      splitLine: {
        show: false
      }

    },
    /* series: [{
        data: [0, 0, 0, 0, 0.8, 0.7, 0, 0, 0, 0.533, 0.88, 0.57],
        name: 'JianQiang',
        type: 'line'
      }] */

    series: series
  }
}

// 饼状图
export function pie(title, data) {
  return {
    title: {
      text: title,
      x: 'center',
      top: 0,
      textStyle: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: 20,
        height: 20,
        color: '#333'
      }
    },
    tooltip: {
      trigger: 'item',
      // formatter: '{b}</br> {c} ({d}%)'
      formatter: function(params) {
        var data = params.data
        var val = parseInt(data.value)
        return data.name + '</br>' + formatNum(val) + '</br>' + params.percent + '%'
      }

    },
    legend: {
      // data: legendData,
      type: 'scroll',
      x: 'center',
      y: 'bottom'
    },
    series: [
      {
        type: 'pie',
        radius: ['30%', '55%'],
        center: ['50%', '50%'],
        selectedMode: 'single',
        // 柱状图的空隙
        /* itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          }, */
        label: {
          normal: {
            show: true,
            // position: 'outside',
            // formatter: `{b}: {c} ({d}%)`
            formatter: function(params) {
              var data = params.data
              var val = parseInt(data.value)
              return data.name + '\n' + formatNum(val) + ' (' + params.percent + '%)'
            }

          }
        },
        emphasis: {
          label: {
            show: true,
            fontWeight: 'bold'
          }

        },
        minAngle: 4,
        labelLine: {
          normal: {
            show: true,
            length: 12,
            // length2: 12,
            lineStyle: {
              width: 2
            },
            smooth: true

          }
        },
        data: data
      }
    ]
  }
}

//  普通柱状图
export function BAR(title, xDataKey, data) {
  return {
    title: {
      text: title,
      x: 'center',
      top: 0,
      textStyle: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: 20,
        height: 20,
        color: '#333'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    dataZoom: [{
      type: 'inside',
      start: 80,
      end: 100
    }, {}],
    xAxis: {
      type: 'category',
      data: xDataKey,
      axisLabel: {
        interval: 0 // 0：表示全部显示不间隔；auto:表示自动根据刻度个数和宽度自动设置间隔个数
      }
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      data: data,
      type: 'bar',
      itemStyle: {
        normal: {
          label: {
            show: true,		// 开启显示
            position: 'top',	// 在上方显示
            textStyle: { // 数值样式
              color: 'black',
              fontSize: 12
            },
            formatter: function(params) {
              if (params.value > 0) {
                return formatNum(params.value)
              } else {
                return ''
              }
            }
          },
          color: '#5470C6'

        }
      }
    }]
  }
}

// 堆叠柱状
export function ddBar(title, legendData, data, maxNum, ratioRate) {
  const series = []
  const arr = ['延期数量', '未结数量']
  const Style = [{
    color: '#91CC75'
  }, { color: '#FAC858' }]
  const pushData = []
  legendData.map(item => {
    pushData.push(0)
  })
  arr.map((item, index) => {
    data.map((e, i) => {
      Style.map((ee, ii) => {
        if (i === index && index === ii) {
          series.push({
            name: item,
            type: 'bar',
            stack: 'total',
            label: {
              normal: {
                show: true,
                formatter: function(params) {
                  if (params.value > 0 && params.value > Math.ceil(maxNum / 15)) {
                    return formatNum(params.value)
                  } else {
                    return ''
                  }
                }
              }
            },
            itemStyle: ee,
            emphasis: {
              focus: 'series'
            },
            data: e
          },)
        }
      })
    })
  })
  series.push({
    type: 'bar',
    stack: 'total',
    label: {
      normal: {
        show: true,
        position: 'right',
        formatter: (val) => {
          // val.dataIndex
          for (let i = 0; i < ratioRate[0].length; i++) {
            if (i === val.dataIndex) {
              return ratioRate[0][i] ? (ratioRate[0][i] * 100).toFixed(2) + '%' : ''
            }
          }
        }
      }},
    itemStyle: {
      color: '#000'
    },
    emphasis: {
      focus: 'series'
    },
    data: pushData
  })
  return {
    title: {
      text: title,
      x: 'center',
      top: 0,
      // textVerticalAlign: 'auto',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: 20,
        height: 20,
        color: '#333'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      },
      formatter: function(params) {
        let str = params[0].name
        for (let i = 0; i < params.length - 1; i++) {
          params[i].seriesName ? str += '<br/>' + params[i].marker + params[i].seriesName + ' ' + formatNum(params[i].value) : ''
        }
        return str
      }
    },
    legend: {
      data: ['延期数量', '未结数量'],
      x: 'center',
      y: 'bottom'
    },
    grid: {
      left: '1%',
      right: '8%',
      bottom: '8%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      axisTick: {
        show: false
      }

    },
    yAxis: {
      type: 'category',
      data: legendData,
      position: 'left'

    },
    series }
}
