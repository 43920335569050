<template>
  <div>
    <vxe-table
      ref="yTable"
      v-loading="tableLoading"
      border
      max-height="400px"
      show-overflow
      highlight-current-row
      show-footer
      :footer-method="footerMethod"
      align="center"
      :keyboard-config="{isArrow: true}"
      :data="tabData"
    >
      <template v-if="activeName==='VENDOR'">
        <vxe-table-column field="vendorName" title="供应商" min-width="100" />
      </template>
      <template v-if="activeName==='BU'">
        <vxe-table-column field="bu" title="BU" min-width="100" />
      </template>
      <template v-if="activeName==='SEASON'">
        <vxe-table-column field="seasonStr" title="季节" min-width="100" />
      </template>
      <template v-if="activeName==='DELAY_DAYS'">
        <vxe-table-column field="vendorDelayDaysRange" title="延期天数归类" min-width="100" />
      </template>
      <vxe-table-column field="unclearedDelayNumber" title="未结延期数量" min-width="100" />
      <vxe-table-column field="unclearedDelayRatio" title="未结延期占比" min-width="100" />
      <vxe-table-column field="unclearedNumber" title="未结数量" min-width="100" />
      <vxe-table-column field="unclearedRatio" title="未结占比" min-width="100" />
      <vxe-table-column field="addDelayNumber" title="新增延期数量" min-width="100" />
      <vxe-table-column field="continueDelayNumber" title="继续延期数量" min-width="100" />
    </vxe-table>
  </div>
</template>

<script>
import XEUtils from 'xe-utils'
import { formatNum, delcommafy } from '@/utils/index.js'
export default {
  props: {
    tabData: {
      type: Array,
      default: () => []
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    activeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  watch: {

    'activeName'(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.yTable?.recalculate()
        })
      }
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    footerMethod({ columns, data }) {
      const sum = columns.map((column, columnIndex) => {
        if (columnIndex === 0) {
          return '合计'
        }
        if (['unclearedDelayNumber', 'unclearedNumber', 'addDelayNumber', 'continueDelayNumber'].includes(column.property)) {
          data = data.map(item => {
            return { ...item, unclearedDelayNumber: delcommafy(item.unclearedDelayNumber), unclearedNumber: delcommafy(item.unclearedNumber),
              continueDelayNumber: delcommafy(item.continueDelayNumber), addDelayNumber: delcommafy(item.addDelayNumber) }
          })
          return formatNum(XEUtils.sum((data), column.property))
        }
        if (column.property === 'unclearedRatio') {
          return (XEUtils.sum(data, 'unclearedDelayNumber') / XEUtils.sum(data, 'unclearedNumber') * 100).toFixed(2) + '%'
        }
        return ' '
      })
      return [sum]
    }

  }
}
</script>

<style scoped lang="scss">

</style>
