<template>
  <div>
    <el-form
      ref="queryForm"
      v-model="queryForm"
      :rules="queryRules"
      style="display:flex;"
      label-width="110px"
      label-position="right"
    >
      <div class="el-lt" style="width:90%;">
        <el-row class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item prop="vendorId" :class="$i18n.locale" label="供应商">
              <el-select v-model="queryForm.vendorId" clearable filterable multiple :collapse-tags="true">
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="BU">
              <el-select v-model="queryForm.operatorGroup" clearable filterable multiple :collapse-tags="true">
                <el-option
                  v-for="item in groupoptions"
                  :key="item.groupId"
                  :label="item.groupName"
                  :value="item.groupName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="季节" :class="$i18n.locale">
              <el-select v-model="queryForm.season" value-key="id" multiple clearable filterable>
                <el-option
                  v-for="item in _getAllCommodityDict('SEASON')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Category">
              <el-select v-model="queryForm.category" clearable filterable multiple :collapse-tags="true">
                <el-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :label="item.categoryName"
                  :value="item.categoryCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Style">
              <el-select v-model="queryForm.style" clearable filterable multiple :collapse-tags="true">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="产品定位">
              <el-select v-model="queryForm.productPosition" clearable filterable multiple :collapse-tags="true">
                <el-option
                  v-for="item in _getAllCommodityDict('PRODUCT_POSITION')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="延期天数归类" :class="$i18n.locale">
              <el-select v-model="queryForm.delayDays" :placeholder="$t('page.selectPlaceholder')" filterable multiple collapse-tags>
                <el-option label="0-15天" value="0-15" />
                <el-option label="15-30天" value="15-30" />
                <el-option label="30天以上" value="30" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="延期原因">
              <el-cascader
                v-model="queryForm.delayReasonIds"
                clearable
                collapse-tags
                :options="options"
                :props="{ multiple: true, checkStrictly: false }"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="下单日期">
              <el-date-picker
                v-model="queryForm.value1"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>

        </el-row>
      </div>
      <div class="el-rt" style="wwidth:10%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="fullscreenLoading" size="small" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button size="small" @click="resetClick">{{ $t('page.reset') }}</el-button>
          <el-button type="text" size="small" @click="showRow=!showRow">{{ showRow ? $t('page.hide') : $t('page.expand') }}
          </el-button>
        </el-col>
      </div>

    </el-form>
    <div v-loading="fullscreenLoading">
      <el-row class="grid-content bg-purple-dark mt-1 mb-3">
        <el-col :span="20" class="bg-purple-title">概览数据</el-col>
      </el-row>
      <div style="display:flex;">
        <div v-for="(item,index) in viewDatas" :key="index" class="div">
          <div>{{ item.label }}</div>
          <div style="font-weight:600;">{{ item.value }}</div>
        </div>
      </div>
      <el-row class="grid-content bg-purple-dark mt-3 mb-3">
        <el-col :span="20" class="bg-purple-title">各维度未结延期占比情况</el-col>
      </el-row>
      <!-- Top6供应商pie 1 2-->
      <el-row class="mt-3 mb-3">
        <el-col :span="12">
          <div id="bar-vendor-delay-echarts-container" class="echarts" />
        </el-col>
        <el-col :span="12">
          <div id="line-vendor-wj-echarts-container" class="echarts" />
        </el-col>
      </el-row>
      <!-- 34 -->
      <el-row class="mt-3 mb-3">
        <el-col :span="12">
          <div id="bar-bu-delay-echarts-container" class="echarts" />
        </el-col>
        <el-col :span="12">
          <div id="line-bu-wj-echarts-container" class="echarts" />
        </el-col>
      </el-row>
      <!-- 56 -->
      <el-row class="mt-3 mb-3">
        <el-col :span="12">
          <div id="bar-season-delay-echarts-container" class="echarts" />
        </el-col>
        <el-col :span="12">
          <div id="line-season-wj-echarts-container" class="echarts" />
        </el-col>
      </el-row>
      <!-- 78 -->
      <el-row class="mt-3 mb-3">
        <el-col :span="12">
          <div id="bar-delayDaysList-delay-echarts-container" class="echarts" />
        </el-col>
        <el-col :span="12">
          <div id="line-delayDaysList-wj-echarts-container" class="echarts" />
        </el-col>
      </el-row>
      <!-- 9 10 -->
      <el-row class="mt-5 mb-3">
        <el-col :span="12">
          <div id="bar-drtList-yq-echarts-container" class="echarts" />
        </el-col>
        <el-col :span="12">
          <div id="bar-drList-yq-echarts-container" class="echarts" />
        </el-col>
      </el-row>
      <!-- 本周新增/继续延期情况-->
      <el-row class="grid-content bg-purple-dark mt-3 mb-3">
        <el-col :span="20" class="bg-purple-title">本周新增/继续延期情况</el-col>
      </el-row>
      <!-- Top6供应商bar1 2-->
      <el-row class="mt-3 mb-3">
        <el-col :span="12">
          <div id="bar-vendor-add-echarts-container" style="height:360px;width:100%;padding: 0 15px;" />
        </el-col>
        <el-col :span="12">
          <div id="bar-vendor-continue-echarts-container" style="height:360px;width:100%;padding: 0 15px;" />
        </el-col>
      </el-row>
      <el-row class="mt-3 mb-3">
        <el-col :span="12">
          <div id="bar-bu-add-echarts-container" style="height:360px;width:100%;padding: 0 15px;" />
        </el-col>
        <el-col :span="12">
          <div id="bar-bu-continue-echarts-container" style="height:360px;width:100%;padding: 0 15px;" />
        </el-col>
      </el-row>
      <el-row class="grid-content bg-purple-dark mt-5" type="flex" justify="space-around">
        <el-col class="bg-purple-title">各维度汇总数据</el-col>
      </el-row>
      <el-tabs v-model="groupBy" @tab-click="handleClick">
        <el-tab-pane label="供应商" name="VENDOR">
          <tabtable ref="multipleTable" :active-name="groupBy" :tab-data="tabData" :table-loading="tabLoading" />
        </el-tab-pane>
        <el-tab-pane label="BU" name="BU">
          <tabtable ref="multipleTable" :active-name="groupBy" :tab-data="tabData" :table-loading="tabLoading" />
        </el-tab-pane>
        <el-tab-pane label="季节" name="SEASON">
          <tabtable ref="multipleTable" :active-name="groupBy" :tab-data="tabData" :table-loading="tabLoading" />
        </el-tab-pane>
        <el-tab-pane label="延期天数归类" name="DELAY_DAYS">
          <tabtable ref="multipleTable" :active-name="groupBy" :tab-data="tabData" :table-loading="tabLoading" />
        </el-tab-pane>
      </el-tabs>
      <el-row class="grid-content bg-purple-dark mt-5">
        <el-col :span="20" class="bg-purple-title">明细数据</el-col>
        <el-col :span="4">
          <el-button class="toolbar-icon--button mr-2" type="text" icon="el-icon-download" style="float:left;" :loading="exportPoFileDownLoading" @click="exportFileDownLoad">下载</el-button>
          <VxeColumnSettings v-if="xTableRef" ref="VxeColumnSetting" :container-ref="xTableRef" ref-name="xTable" local-key="table-channel" class="ml-2" @upDate="(val) => $refs.xTable.reloadColumn(val)">
            <el-button class="toolbar-icon--button" type="text" icon="el-icon-s-fold" style="float:left;" @click="$refs['VxeColumnSetting'].visibleShow()">展示列设置</el-button>
          </VxeColumnSettings>
        </el-col>
      </el-row>

      <vxe-table
        ref="xTable"
        v-loading="tableLoading"
        border
        max-height="500px"
        resizable
        show-overflow
        highlight-current-row
        align="center"
        :keyboard-config="{isArrow: true}"
        :data="tableData"
      >
        <vxe-table-column field="vendorName" title="供应商" min-width="100" />
        <vxe-table-column field="bg" title="BG" width="60" />
        <vxe-table-column field="bu" title="BU" min-width="60" />
        <vxe-table-column field="seasonStr" title="季节" min-width="80" />
        <vxe-table-column title="类别" min-width="100">
          <template v-slot="{row}">
            {{ row.category2Code }}>>{{ row.category3Code }}
          </template>
        </vxe-table-column>
        <vxe-table-column field="style" title="Style" min-width="100" />
        <vxe-table-column field="productPosition" title="产品定位" min-width="100" />
        <vxe-table-column field="purchaseOrderCode" title="采购订单号" min-width="100" />
        <vxe-table-column field="orderTime" title="下单日期" min-width="100" />
        <vxe-table-column field="isFirst" title="是否首单" min-width="100">
          <template v-slot="{row}">
            <span v-if="row.isFirst==true">是</span>
            <span v-if="row.isFirst==false">否</span>
          </template>
        </vxe-table-column>
        <vxe-table-column field="purchasePersonnel" title="采购员" min-width="100" />
        <vxe-table-column field="operator" title="Operator" min-width="100" />
        <vxe-table-column field="orderTotalNumber" title="订单总数" min-width="100" />
        <vxe-table-column field="otNumber" title="应交数量" min-width="100" />
        <!-- <vxe-table-column field="style" title="缺量数量" min-width="100" /> -->
        <vxe-table-column field="actualCollectDate" title="实际交货日期" min-width="100" />
        <vxe-table-column field="actualCollectNumber" title="实际交货数量" min-width="100" />
        <vxe-table-column field="vendorReviewDate" title="供应商评审交货时间" min-width="150" />
        <vxe-table-column field="vendorReviewWeek" title="供应商评审交货周" min-width="150" />
        <vxe-table-column field="isOtByVendor" title="供应商交货是否准交" min-width="150">
          <template v-slot="{row}">
            <span v-if="row.isOtByVendor==true">是</span>
            <span v-if="row.isOtByVendor==false">否</span>
          </template>
        </vxe-table-column>
        <vxe-table-column field="purchaseFeedbackDate" title="采购反馈交期" min-width="100" />
        <vxe-table-column field="purchaseFeedbackUpdateTime" title="采购反馈交期更新日期" min-width="150" />
        <vxe-table-column field="purchaseFeedbackWeek" title="采购反馈交期更新周" min-width="150" />
        <vxe-table-column field="vendorDelayDays" title="供应商延期天数" min-width="150" />
        <vxe-table-column field="vendorDelayDaysRange" title="供应商延期天数归类" min-width="180" />
        <vxe-table-column field="vendorReviewDelayNumber" title="评审延期数量" min-width="100" />
        <vxe-table-column field="delayReasonType" title="延期原因类型" min-width="100" />
        <vxe-table-column field="delayReason" title="延期原因" min-width="100" />
      </vxe-table>
      <!-- 明细分页 -->
      <Paging :pager="pager" end @pagination="pagerUpdate" />

    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import Paging from '@/components/Pagination'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { queryCategoryList } from '@/api/listSelection'
import { exportUnclearedList, getDelayReason, chartContinueDelayOrder, chartAddDelayOrder, reportUnclearedDelayGroupBy, reportUnclearedDelayOverview, pageUnclearedDelayDetail, chartUnclearedDelayByAll } from '@/api/safestock.js'
import { queryVendorList, listBuAndOperator, queryStyleList } from '@/api/scm-api'
import { pie, BAR, ddBar } from '@/utils/echarts.js'
import VxeColumnSettings from '@/components/VxeColumnSettings'
import tabtable from '../component/tabtable'
import { deepClone, formatNum } from '@/utils/index.js'

export default {
  components: { VxeColumnSettings, tabtable, Paging },
  mixins: [commodityInfoDict],
  data() {
    return {
      arr: ['vendorReviewWeek', 'planDeliveryWeek', 'purchaseFeedbackWeek'],
      exportPoFileDownLoading: false,
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      categoryList: [],
      fullscreenLoading: false,
      viewDatas: [],
      tabLoading: false,
      tabData: [],
      styleOptions: [],
      groupoptions: [],
      groupBy: 'VENDOR',
      detailData: [],
      xTableRef: undefined,
      tableLoading: false,
      tableData: [],
      queryForm: {
        value1: [],
        delayReasonIds: []
      },
      vendorOptions: [],
      TableLoading: false,
      showRow: false,
      queryRules: {},
      options: [],
      viewArr: [
        { label: '订单总数', val: 'orderTotalNumber' },
        { label: '未结延期数量', val: 'unclearedDelayNumber' },
        { label: '未结数量', val: 'unclearedNumber' },
        { label: '未结占比', val: 'unclearedRatio' },
        { label: '新增延期数量', val: 'addDelayNumber' },
        { label: '继续延期数量', val: 'continueDelayNumber' }
      ]
    }
  },
  computed: {
    queryParams() {
      const { value1 } = this.queryForm
      const [placeOrderStartTime, placeOrderEndTime] = value1 || []
      const obj = this.handleData()
      delete obj.value1
      // 二维数组转化
      const ids = []
      this.queryForm.delayReasonIds.map((item, index) => {
        item.map((e, INDEX) => {
          Number(e) && ids.push(Number(e))
        })
      })
      return Object.assign({}, obj, { placeOrderStartTime, placeOrderEndTime, delayReasonIds: String(ids) || '' })
    },
    queryTabParams() {
      return Object.assign({}, this.queryParams, { groupBy: this.groupBy })
    },
    tableDataParams() {
      return Object.assign({}, this.queryParams, this.pager)
    }
  },
  created() {
    this.$nextTick(() => {
      this.xTableRef = this.$refs.xTable
    })
  },
  beforeDestroy() {

  },
  mounted() {
    this._getDelayReason()
    this._queryVendorList()
    this._queryBuList()
    this._queryStyleList()
    this._queryCategoryList()
    this.queryClick()
  },

  methods: {
    async pagerUpdate(val) {
      this.pager = val
      try {
        this.tableLoading = true
        const { code: detailCode, datas: { records, pager }} = await pageUnclearedDelayDetail(this.tableDataParams)
        if (detailCode === 0) {
          this.tableData = records.map(item => {
            for (const i in item) {
              if (/^[0-9]+.?[0-9]*/.test(item[i]) && !this.arr.includes(i)) {
                item[i] = formatNum(item[i])
              }
            }
            return item
          })
          this.pager = pager
        }
      } finally {
        this.tableLoading = false
      }
    },
    handleData() {
      const obj = deepClone(this.queryForm)
      for (const i in obj) {
        if (i !== 'value1' && i !== 'delayReasonIds') {
          obj[i] = String(obj[i]) || ''
        }
      }
      return obj
    },
    async _queryCategoryList() {
      const { datas } = await queryCategoryList({ categoryLevel: 3 })
      this.categoryList = datas
    },
    async _getDelayReason() {
      const { datas } = await getDelayReason()
      const name = []
      const value = []
      const options = []
      Object.keys(datas).map(item => {
        name.push(item)
      })
      Object.values(datas).map(item => {
        value.push(item)
      })
      name.map((item, index) => {
        value.map((e, i) => {
          if (index === i) {
            options.push({ value: item, label: item, children: e })
          }
        })
      })
      this.options = options.map((item, index) => {
        const children = item.children.map(i => {
          return { value: i.delayKey, label: i.delayReason }
        })
        return { value: item.value, label: item.label, children }
      })
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    async _queryBuList() {
      const { datas: { list }} = await listBuAndOperator()
      this.groupoptions = list
    },
    async handleClick() {
      try {
        this.tabLoading = true
        const { code, datas } = await reportUnclearedDelayGroupBy(this.queryTabParams)
        if (code === 0) {
          this.tabData = datas.map(item => { return { ...item, unclearedNumber: formatNum(item.unclearedNumber), unclearedDelayNumber: formatNum(item.unclearedDelayNumber), unclearedDelayRatio: (item.unclearedDelayRatio * 100).toFixed(2) + '%', unclearedRatio: (item.unclearedRatio * 100).toFixed(2) + '%' } })
        }
        this.$nextTick(() => {
          this.$refs.multipleTable?.$refs?.yTable?.recalculate()
        })
      } finally {
        this.tabLoading = false
      }
    },

    async exportFileDownLoad() {
      try {
        this.exportPoFileDownLoading = true
        const { datas } = await exportUnclearedList(this.queryParams)
        window.open(datas[0], '_blank')
      } finally {
        this.exportPoFileDownLoading = false
      }
    },
    preText(pretext) {
      // return pretext && pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, ',')
      const arr = []
      const array = pretext && pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, ',').replace(/\s/g, '&nbsp;').split(',') || []
      array.map(item => {
        if (item) {
          arr.push(item)
        }
      })
      if (arr.length > 50) {
        this.$message({
          message: '最大输入50个采购单号',
          type: 'warning'
        })
        return
      }
      return arr.join(',')
    },
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    resetClick() {
      // this.queryForm = {}
      this.queryForm = this.$options.data().queryForm
      this.queryClick()
    },
    async queryClick(flag) {
      this.viewDatas = []
      this.pager.current = 1
      try {
        this.fullscreenLoading = true
        const data = []

        const barlendData = [[], [], [], []]
        const dom = ['bar-vendor-delay-echarts-container', 'bar-bu-delay-echarts-container', 'bar-season-delay-echarts-container',
          'bar-delayDaysList-delay-echarts-container', 'bar-drtList-yq-echarts-container', 'bar-drList-yq-echarts-container']
        const dom2 = ['line-vendor-wj-echarts-container', 'line-bu-wj-echarts-container', 'line-season-wj-echarts-container', 'line-delayDaysList-wj-echarts-container']
        const title = ['供应商未结延期占比', 'BU未结延期占比', '季节未结延期占比',
          '延期天数归类未结延期占比', '延期类型未结延期占比', '供应商原因未结延期占比']
        const title2 = ['供应商未结占比', 'BU未结占比', '季节未结占比',
          '延期天数归类未结占比']

        const { code: detailCode, datas: { records, pager }} = await pageUnclearedDelayDetail(this.tableDataParams)
        if (detailCode === 0) {
          this.tableData = records.map(item => {
            for (const i in item) {
              if (/^[0-9]+.?[0-9]*/.test(item[i]) && !this.arr.includes(i)) {
                item[i] = formatNum(item[i])
              }
            }
            return item
          })
          this.pager = pager
        }

        const { code, datas } = await reportUnclearedDelayGroupBy(this.queryTabParams)
        if (code === 0) {
          this.tabData = datas.map(item => { return { ...item, unclearedNumber: formatNum(item.unclearedNumber), unclearedDelayNumber: formatNum(item.unclearedDelayNumber), unclearedDelayRatio: (item.unclearedDelayRatio * 100).toFixed(2) + '%', unclearedRatio: (item.unclearedRatio * 100).toFixed(2) + '%' } })
        }

        // 图表接口
        if (!flag) {
          const { code: allCode, datas: { vendorList, buList, delayDaysList, drList, drtList, seasonList }} = await chartUnclearedDelayByAll(this.queryParams)
          const arr = [vendorList, buList, seasonList, delayDaysList, drtList, drList]
          //  /^[1-9]?[0-9]*\.[0-9]*$/
          if (allCode === 0) {
            for (let i = 0; i < arr.length; i++) {
              data[i] = arr[i].map(item => {
                return { value: item.unclearedDelayNumber, name: item.title }
              })
            }
            // 堆叠柱状图lengendata
            const barArr = [vendorList, buList, seasonList, delayDaysList]
            const dataArr = [[], [], [], []]
            const dataRatio = [[], [], [], []]
            for (let j = 0; j < barArr.length; j++) {
              const arr1 = []
              const arr2 = []
              const arr3 = []
              barArr[j].map((item, index) => {
                barlendData[j].push(item.title)
                arr1.push(item.unclearedDelayNumber)
                arr2.push(item.unclearedNumber)
                arr3.push(item.unclearedRatio)
              })
              dataRatio.map((item, index) => {
                if (j === index) {
                  dataRatio[index].push(arr3)
                }
              })
              dataArr[j].push(arr1)
              dataArr[j].push(arr2)
            }
            console.log(dataRatio)
            data.map((item, index) => {
              dom.map((e, i) => {
                title.map((E, Index) => {
                  if (index === i && i === Index) {
                    const chart1 = echarts.init(document.getElementById(e))
                    const options1 = pie(E, item)
                    chart1.setOption(options1)
                    window.addEventListener('resize', () => {
                      chart1.resize()
                    })
                  }
                })
              })
            })
            let maxNum = ''
            dataArr.map((item, index) => {
              // item二维数组
              maxNum = item[0][0]
              item.map(e => {
                e.map(i => {
                  if (i > maxNum) {
                    maxNum = i
                  }
                })
              })
              // console.log(maxNum)
              dom2.map((e, i) => {
                barlendData.map((E, INDEX) => {
                  title2.map((ee, ii) => {
                    dataRatio.map((eee, iii) => {
                      if (index === i && i === INDEX && INDEX === ii && ii === iii) {
                        const chart1 = echarts.init(document.getElementById(e))
                        // eee比例数组
                        const options1 = ddBar(ee, E, item, maxNum, eee)
                        chart1.setOption(options1)
                        window.addEventListener('resize', () => {
                          chart1.resize()
                        })
                      }
                    })
                  })
                })
              })
            })
          }
          const { code: addBarCode, datas: { vendorList: vendorAddList, buList: buAddList }} = await chartAddDelayOrder(this.queryParams)

          if (addBarCode === 0) {
            const addVendorList = []
            const addVendorDataList = []
            const addBuList = []
            const addBuDataList = []
            vendorAddList.map(item => {
              addVendorList.push(item.title)
              addVendorDataList.push(item.number)
            })
            buAddList.map(item => {
              addBuList.push(item.title)
              addBuDataList.push(item.number)
            })
            const chart1 = echarts.init(document.getElementById('bar-vendor-add-echarts-container'))
            const options1 = BAR('供应商新增延期数量', addVendorList, addVendorDataList)
            chart1.setOption(options1)
            window.addEventListener('resize', () => {
              chart1.resize()
            })
            const chart3 = echarts.init(document.getElementById('bar-bu-add-echarts-container'))
            const options3 = BAR('BU新增延期数量', addBuList, addBuDataList)
            chart3.setOption(options3)
            window.addEventListener('resize', () => {
              chart3.resize()
            })
          }
          const { code: continueCode, datas: { vendorList: vendorContinueList, buList: buContinueList }} = await chartContinueDelayOrder(this.queryParams)
          if (continueCode === 0) {
            const addVendorList = []
            const addVendorDataList = []
            const addBuList = []
            const addBuDataList = []
            vendorContinueList.map(item => {
              addVendorList.push(item.title)
              addVendorDataList.push(item.number)
            })
            buContinueList.map(item => {
              addBuList.push(item.title)
              addBuDataList.push(item.number)
            })
            //
            const chart2 = echarts.init(document.getElementById('bar-vendor-continue-echarts-container'))
            const options2 = BAR('供应商继续延期数量', addVendorList, addVendorDataList)
            chart2.setOption(options2)
            const chart4 = echarts.init(document.getElementById('bar-bu-continue-echarts-container'))
            const options4 = BAR('BU继续延期数量', addBuList, addBuDataList)
            chart4.setOption(options4)
            window.addEventListener('resize', () => {
              chart2.resize()
              chart4.resize()
            })
          }
          const { code: Code, datas: viewDatas } = await reportUnclearedDelayOverview(this.queryParams)
          if (Code === 0) {
            for (const i in viewDatas) {
              const label = this.viewArr.find(item => item.val === i)?.label
              this.viewDatas.push({ label, value: formatNum(viewDatas[i]) })
            }
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.fullscreenLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-range-input {
  width: 90px !important;
}
/deep/.el-date-editor {
  width: 100%;
}
.div{
  flex:1;
  border: 1px solid rgba(121, 121, 121, 1);
  margin-right:10px;
  width: 100px;
  height:70px;
  display: flex;
  flex-direction:column;
  justify-content:center;
  align-items: center;line-height: 40px;
border-radius: 10px;
// box-shadow:1px 1px 1px 1px rgba(0,0,0,.3)
}
.echarts {
  height:450px;
  width:100%;
}
</style>
